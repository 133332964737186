import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  { name: 'getWarehousesFlash', url: `${environment.beijingApiUrl}/filters/warehouses`, method: 'get'},
  { name: 'getCarriersFlash', url: `${environment.beijingApiUrl}/filters/carriers`, method: 'get'},
  { name: 'getPudosFlash', url: `${environment.beijingApiUrl}/filters/pudos`, method: 'get'},
  { name: 'getAgenciesFlash', url: `${environment.beijingApiUrl}/filters/agencies`, method: 'get'},
  { name: 'postDeliveryStatus', url: `${environment.beijingApiUrl}/shippers/{shipper_id}/deliveries/{delivery_id}/statuses`, method: 'post'},
  { name: 'getZipcode', url: `${environment.apiPlatform}/v1/zip-codes/{country_id}`, method: 'get' },
  { name: 'getLevels', url: `${environment.dubaiApiUrl}/countries/{country}/levels`, method: 'get'}
  // { name: 'getCarriersFlash', url: `${environment.beijingApiUrl}/shippers/{shipper_id}/configuration`, method: 'get'},
  // { name: 'updateCarriersActivated', url: `${environment.beijingApiUrl}/shippers/{shipper_id}/configuration`, method: 'put'},
  // { name: 'assignToSummary', url: `${environment.estambulApiUrl}/deliveries/assign-to-summary`, method: 'put'}
]

export default class ReturnsService {
  constructor(context) {
    this.context = context
  }
  async callService (name, queryParams = {}, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params)
  }
  
  async callMultipleServices (callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }

  setEnvironment () {
    return environment.platformUrl
  }
}